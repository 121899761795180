import React from "react";
import { Box, Grid, IconButton, styled, Typography } from "@mui/material";

import userLogo from "../../resources/images/svg/header/user.svg";
import logo from "../../resources/images/svg/inexplore-logo-white.svg";
import { getLoginRedirectURL, useIsLoggedIn } from "../../hooks/auth";
import UserAvatar from "./UserAvatar";
import BasketButton from "../basket/BasketButton";
import Universe from "./Universe";
import HeaderNav from "./HeaderNav";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import SmallHeader from "./SmallHeader";

const Container = styled(Grid)(({ theme }) => {
	return {
		width: "100%",
		minHeight: "82px",
		top: 0,
		right: 0,
		left: 0,
		position: "sticky",
		display: "flex",
		zIndex: theme.zIndex.appBar,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		background: "linear-gradient(90deg,#162a41 0,#8f6c9f)",
	};
});
const Wrapper = styled(Grid)(({ theme }) => {
	return {
		boxSizing: "border-box",
		display: "flex",
		width: "100%",
		padding: "0 20px",
		maxWidth: "1500px",
		margin: "0 auto",
		minHeight: "100%",
	};
});

export const BackgroundBox = styled(Box)((theme) => {
	return {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		background: "linear-gradient(90deg, #162941 0%, #8F6C9F 100%)",
		overflow: "hidden",
	};
});

export const GradientTypo = styled(Typography)(({ theme, disabled = false, fontSize = "40px" }) => {
	return {
		fontWeight: 700,
		background:
			disabled === false
				? "linear-gradient(90deg, #EE7992 0%, #F1AE65 50.51%, #F4C54D 100%)"
				: theme.palette.grey["400"],
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
		overflow: "hidden",
		fontFamily: "Branding Bold",
		fontSize: fontSize,
		display: "block",
		"&:first-letter": {
			textTransform: "capitalize !important",
		},
	};
});

export const Subtitle = styled(Typography)((theme) => {
	return {
		textAlign: "left",
		color: "white",
		fontFamily: "Branding SemiBold",
		fontWeight: 600,
		fontSize: 20,
		maxWidth: 700,
	};
});

export const UserInitialsContainer = styled(Box)(({ theme }) => {
	return {
		backgroundColor: "rgba(36,38,56,0.53)",
		borderRadius: "50%",
		height: 42,
		width: 42,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		transition: theme.transitions.create(["box-shadow"]),
		"&:hover": {
			boxShadow: "0 0 15px 0 rgb(36 38 56 / 53%)",
		},
	};
});

export const Banner = styled(Box)(({ theme }) => {
	return {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "37px",
		backgroundColor: "#9acfc8",
	};
});

const Header = () => {
	const isLoggedIn = useIsLoggedIn();

	const redirectUrl = getLoginRedirectURL();

	const { width } = useWindowDimensions();

	if (width < 1207) {
		return <SmallHeader />;
	}

	return (
		<Container>
			<Wrapper>
				<Grid item justifyContent={"flex-start"} alignItems={"center"} display={"flex"}>
					<a href={"https://www.inexplore.com"}>
						<img
							src={logo}
							className=""
							alt="logo"
							style={{ height: "54px", width: "120px", marginBottom: "5px" }}
						/>
					</a>
				</Grid>
				<Grid
					item
					container
					justifyContent={"flex-end"}
					alignItems={"center"}
					display={"flex"}
					columnSpacing={3}
					flexWrap={"nowrap"}
					ml={0.5}
				>
					<Grid item>
						<HeaderNav />
					</Grid>
					<Grid item>
						<Universe />
					</Grid>
					<Grid item>
						<BasketButton />
					</Grid>
					<Grid item>
						{isLoggedIn ? (
							<UserAvatar />
						) : (
							<a href={redirectUrl}>
								<IconButton sx={{ width: 45, height: 45 }} size={"medium"}>
									<img src={userLogo} alt={"user-logo"} />
								</IconButton>
							</a>
						)}
					</Grid>
				</Grid>
			</Wrapper>
			<Banner>
				<Typography
					sx={{
						fontFamily: "Branding Bold",
					}}
				>
					📆 De la magie chez vous ! Offrez-vous notre{" "}
					<Typography
						component={"a"}
						href="https://boutique.inexplore.com"
						target="_blank"
						className="link"
						sx={{
							fontFamily: "Branding Bold",
							color: (theme) => theme.palette.secondary.main,
						}}
					>
						almanach spirituel 2025
					</Typography>
				</Typography>
			</Banner>
		</Container>
	);
};

export default Header;
