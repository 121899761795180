import { store } from "../store";
import { encode } from "querystring";
import { api } from "./rtkApi";

export const userApi = api.injectEndpoints({
	endpoints: (build) => ({
		getAuthKey: build.query({
			query: ({ inreesId, inreesToken, affiliation, ts }) => {
				return {
					method: "POST",
					url: `/user/cle?ts=${ts}`,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: inreesId,
						token: inreesToken,
						affiliation
					}),
				};
			},
		}),
		getUserAddresses: build.query({
			query: ({}) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				let idinexplore = state.auth.inreesId;
				return {
					method: "POST",
					url: `/user/list-adresses`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
					}),
				};
			},
		}),
		createUserAddress: build.query({
			query: ({
				authToken,
				idinexplore,
				civilite,
				nom,
				prenom,
				address,
				complement1,
				complement2,
				ville,
				cp,
				idpays,
			}) => {
				return {
					method: "POST",
					url: `/user/ajouter-adresse`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
						civilite: civilite === false ? "" : civilite,
						nom,
						prenom,
						adresse: address,
						complement1,
						complement2,
						ville,
						cp,
						idpays,
					}),
				};
			},
		}),
		editUserAddress: build.query({
			query: ({
				authToken,
				idinexplore,
				civilite,
				nom,
				prenom,
				address,
				idadresse,
				complement1,
				complement2,
				ville,
				cp,
				idpays,
			}) => {
				return {
					method: "POST",
					url: `/user/modifier-adresse`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
						civilite: !["Madame", "Monsieur"].includes(civilite) ? "" : civilite,
						nom,
						prenom,
						adresse: address,
						idadresse: idadresse,
						complement1,
						complement2,
						ville,
						cp,
						idpays,
					}),
				};
			},
		}),
		deleteAddress: build.query({
			query: ({ authToken, idinexplore, address }) => {
				return {
					method: "POST",
					url: `/user/supprimer-adresse`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
						idadresse: address.idadresse,
					}),
				};
			},
		}),
		getUser: build.query({
			query: () => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				let idinexplore = state.auth.inreesId;
				return {
					method: "GET",
					url: `/user/infos`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
				};
			},
		}),
		getCreditCards: build.query({
			query: () => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/user/get-cbs`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
				};
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue?.infos;
			},
		}),
		getSepa: build.query({
			query: () => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/user/get-sepa`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
				};
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue?.sepas;
			},
		}),
		updateUser: build.mutation({
			query: ({ civilite, nom, prenom, telephone, portable }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/user/set-infos`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						civilite,
						nom,
						prenom,
						telephone,
						portable,
					}),
				};
			},
		}),
		updateUserNewsletters: build.mutation({
			query: ({ hasNotif, hasPromo, hasActu, hasSeb }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/user/set-newsletters`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						notif: hasNotif ? 1 : 0,
						promo: hasPromo ? 1 : 0,
						actu: hasActu ? 1 : 0,
						film: hasSeb ? 1 : 0,
					}),
				};
			},
		}),
		resetUserPassword: build.mutation({
			query: ({ email }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/user/pass`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						email,
					}),
				};
			},
		}),
		setUserPassword: build.mutation({
			query: ({ oldpass, pass }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/user/set-password`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						oldpass,
						pass,
					}),
				};
			},
		}),
		getUserOdyssees: build.query({
			query: () => {
				const state = store.getState();
				const authToken = state.auth.authToken;
				const idinexplore = state.auth.inreesId;
				return {
					method: "GET",
					url: `/odyssee/all-odyssees-user?idinexplore=${idinexplore}`,
					authorization: "Bearer " + authToken,
				};
			},
		}),
		getUserHasOdyssee: build.query({
			query: ({ id }) => {
				const state = store.getState();
				const authToken = state.auth.authToken;
				const idinexplore = state.auth.inreesId;
				return {
					method: "GET",
					url: `/odyssee/has-odyssee/${id}?idinexplore={idinexplore}`,
					authorization: "Bearer " + authToken,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetAuthKeyQuery,
	useLazyGetAuthKeyQuery,
	useGetUserAddressesQuery,
	useLazyGetUserAddressesQuery,
	useCreateUserAddressQuery,
	useLazyCreateUserAddressQuery,
	useEditUserAddressQuery,
	useLazyEditUserAddressQuery,
	useDeleteAddressQuery,
	useLazyDeleteAddressQuery,

	useGetUserQuery,
	useLazyGetUserQuery,
	useGetCreditCardsQuery,
	useGetSepaQuery,
	useUpdateUserMutation,
	useUpdateUserNewslettersMutation,

	useResetUserPasswordQuery,
	useLazyResetUserPasswordQuery,
	useSetUserPasswordMutation,

	useGetUserOdysseesQuery,
	useLazyGetUserOdysseesQuery,

	useGetUserHasOdysseeQuery,
	useLazyGetUserHasOdysseeQuery,
} = userApi;
