import { createSlice } from "@reduxjs/toolkit";
import { calculateProductPrice } from "../../components/basket/BasketProductCard";

export const initialState = {
	products: [],
	selectedBilling: null,
	allCountries: null,
	applyTip: false,
	tipAmount: 2,
	tipPeriod: 1,
	order: null,
	paymentMethod: null,
	promo: null,
	selectedAddress: null,
	selectedAddressCost: null,
	previousOrder: null,
};

const slice = createSlice({
	name: "basket",
	initialState,
	reducers: {
		resetBasket(state) {
			state.previousOrder = state.order;
			state.products = initialState.products;
			state.selectedBilling = initialState.selectedBilling;
			state.allCountries = initialState.allCountries;
			state.applyTip = initialState.applyTip;
			state.tipAmount = initialState.tipAmount;
			state.tipPeriod = initialState.tipPeriod;
			state.order = initialState.order;
			state.paymentMethod = initialState.paymentMethod;
			state.promo = initialState.promo;
			state.selectedAddress = initialState.selectedAddress;
			state.selectedAddressCost = initialState.selectedAddressCost;
		},
		addProduct(state, { payload: { infos, quantity, ...rest } }) {
			state.products.push({
				id: (Math.random() + 1).toString(36).substring(7),
				infos: infos,
				quantity: quantity ?? 1,
				...rest,
			});
		},
		setAllCountries(state, action) {
			state.allCountries = action.payload;
		},
		changeProductQuantity(state, { payload: { id, quantity, ...rest } }) {
			const pIdx = state.products.findIndex((e) => e.id === id);
			if (pIdx !== -1) {
				if (quantity > 0) {
					const infos = rest?.infos ?? state.products[pIdx].infos;

					state.products[pIdx] = {
						...state.products[pIdx],
						quantity,
						...rest,
						infos: {
							...infos,
							updatedAt: Date.now(),
						},
					};
				} else {
					state.products.splice(pIdx, 1);
				}
			}
		},
		editProduct(state, { payload: { id, ...rest } }) {
			const pIdx = state.products.findIndex((e) => e.id === id);
			if (pIdx !== -1) {
				const infos = rest?.infos ?? state.products[pIdx].infos;
				state.products[pIdx] = {
					...state.products[pIdx],
					...rest,
					quantity: state.products[pIdx].quantity, // prevent quantity to be edited.
					infos: {
						...infos,
						updatedAt: Date.now(),
					},
				};
				//console.log(state.products[pIdx]);
			}
		},
		setBasketProductId(state, { payload: { oldId, id, updatedAt, ...rest } }) {
			const pIdx = state.products.findIndex((e) => e.id === oldId);
			if (state.products[pIdx]) {
				state.products[pIdx].id = id;
				state.products[pIdx].synched = true;
				state.products[pIdx].infos.updatedAt = updatedAt;
			}
		},
		setSelectedAddress(state, action) {
			state.selectedAddress = action.payload;
		},
		setSelectedAddressCost(state, action) {
			state.selectedAddressCost = action.payload;
		},
		setApplyTip(state, action) {
			state.applyTip = action.payload;
		},
		setTipAmount(state, action) {
			state.tipAmount = action.payload;
		},
		setTipPeriod(state, action) {
			state.tipPeriod = action.payload;
		},
		setOrder(state, action) {
			state.order = action.payload;
		},
		setPaymentMethod(state, action) {
			state.paymentMethod = action.payload;
		},
		setPromo(state, action) {
			state.promo = action.payload;
		},
	},
});

export default slice.reducer;
export const {
	addProduct,
	changeProductQuantity,
	editProduct,
	setBasketProductId,
	setSelectedAddress,
	setSelectedAddressCost,
	setAllCountries,
	setApplyTip,
	setTipAmount,
	setTipPeriod,
	setOrder,
	setPaymentMethod,
	setPromo,
	resetBasket,
} = slice.actions;

export const getTotalPrice = (state) => {
	let total = [0, 0, 0, 0];
	const user = state.auth.user;
	state.basket?.products?.forEach((basketProduct) => {
		if (!basketProduct) return;
		const [price, promo, priceAfterPromo] = calculateProductPrice(basketProduct.infos, user);

		total[0] += price * 100 * basketProduct.quantity;
		total[1] += promo * 100 * basketProduct.quantity;
		total[2] += priceAfterPromo * 100 * basketProduct.quantity;
		total[3] += (price * 100 * basketProduct.quantity) / (1 + basketProduct.infos.tva / 10000);
	});
	if(state.basket.promo) {
		total[1] += state.basket.promo.montant;
		total[0] -= state.basket.promo.montant;
	}
	return total;
};

export const getProducts = (state) => {
	return state.basket.products;
};

export const getSelectedAddress = (state) => state.basket.selectedAddress;
export const getSelectedAddressCost = (state) => state.basket.selectedAddressCost;

export const getSelectedBilling = (state) => state.basket.selectedBilling;

export const getAllCountries = (state) => state.basket.allCountries;

export const getApplyTip = (state) => state.basket.applyTip;

export const getTipAmount = (state) => state.basket.tipAmount;

export const getTipPeriod = (state) => state.basket.tipPeriod;
export const getOrder = (state) => state.basket.order;

export const getPaymentMethod = (state) => state.basket.paymentMethod;

export const getPromo = (state) => state.basket.promo;

export const getPreviousOrder = (state) => state.basket.previousOrder;
