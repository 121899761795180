import React from "react";
import { Chip, Grid, Grow } from "@mui/material";

const Anchor = ({ id, label, timeout = 0, duration = 200, ...props }) => {
	const [show, setShow] = React.useState(false);

	React.useEffect(() => {
		setTimeout(() => {
			setShow(true);
		}, timeout);
	}, []);

	return (
		<Grid item>
			<Grow in={show} timeout={duration}>
				<Chip
					label={label}
					{...props}
					sx={{
						...props.sx,
						fontSize: "16px",
						color: (theme) => theme.palette.secondary.main,
						backgroundColor: (theme) => theme.palette.lightgreen,
						padding: (theme) => `${theme.spacing(3)} ${theme.spacing(2)}`,
						borderRadius: (theme) => theme.spacing(3),
					}}
					onClick={() => {
						const element = document.getElementById(id);
						if (element) {
							const rect = element.getBoundingClientRect();
							window.scrollTo({ top: window.scrollY + rect.top - 130 });
						}
					}}
				/>
			</Grow>
		</Grid>
	);
};

const AnchorsList = ({ anchors, ...props }) => {
	return (
		<Grid container spacing={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
			{anchors.map((anchor, index) => (
				<Anchor key={anchor.id} timeout={index * 200} duration={350} {...anchor} {...props} />
			))}
		</Grid>
	);
};

export default AnchorsList;
