import React from "react";

const OdysseeThemeContext = React.createContext(null);

export const useOdysseeTheme = (themeScope = undefined) => {
	const theme = React.useContext(OdysseeThemeContext);

	return React.useMemo(() => {
		return themeScope && theme[themeScope] ? theme[themeScope] : theme;
	}, [theme, themeScope]);
};

const OdysseeThemeProvider = ({ odyssee, children }) => {
	const oTheme = React.useMemo(() => {
		return JSON.parse(odyssee?.themeBuilder ?? "{}");
	}, [odyssee]);

	return <OdysseeThemeContext.Provider value={oTheme}>{children}</OdysseeThemeContext.Provider>;
};

export default OdysseeThemeProvider;
