import { api } from "./rtkApi";
import { encode } from "querystring";

export const orderApi = api.injectEndpoints({
	endpoints: (build) => ({
		createOrder: build.mutation({
			query: ({
				authToken,
				idadresse,
				total,
				codepromo,
				don,
				typedon,
				typepaiement,
				shippingCost,
				details,
				ts,
			}) => {
				return {
					method: "POST",
					url: `/order/create?ts=${ts}`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({ idadresse, total, codepromo, don, typedon, typepaiement, shippingCost, details }),
				};
			},
		}),
		checkPromo: build.mutation({
			query: ({ authToken, codepromo, idinexplore, products }) => {
				return {
					method: "POST",
					url: `/common/verif-promo`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({ codepromo, idinexplore, products: JSON.stringify(products) }),
				};
			},
		}),
		getOrder: build.query({
			query: ({ idcommande }) => {
				return {
					method: "GET",
					url: `/order/get?idcommande=${idcommande}`,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useCreateOrderMutation, useCheckPromoMutation, useGetOrderQuery, useLazyGetOrderQuery } = orderApi;
