import React from "react";
import { Box, Fade, IconButton, Skeleton, Typography, useTheme } from "@mui/material";
import InexCard from "../../../InexCard";
import PriceFormater from "../../../PriceFormater";
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded, ShoppingBagOutlined } from "@mui/icons-material";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { useIsMobile } from "../../../../hooks/responsive";

export const MagazineCard = ({ product, ...props }) => {
	const json = React.useMemo(() => {
		return product?.datajson && product?.datajson !== "" ? JSON.parse(product?.datajson) : {};
	}, [product]);

	const handleClick = React.useCallback(
		(e) => {
			window.location = `https://inexplore.com/magazines/${json?.url}`;
		},
		[json],
	);

	return (
		<InexCard
			sx={{
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0,
				display: "flex",
				flexDirection: "column",
				height: "100%",
				minHeight: "100%",
				userSelect: "none",
				flexGrow: 1,
				boxShadow: (theme) => theme.shadows[26],
			}}
			onClick={json && json.url ? handleClick : undefined}
		>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					overflow: "hidden",
					position: "relative",
					aspectRatio: 174 / 224,
					"& > img": {
						objectFit: "cover",
						width: "100%",
					},
				}}
			>
				<img src={`https://medias.inrees.com/img/produit/moyen_${product?.id}.jpg`} alt={"magazineImage"} />
			</Box>
			<Box
				sx={{
					display: "flex",
					padding: 2,
					flexDirection: "column",
					justifyContent: "space-between",
					flexGrow: 1,
				}}
			>
				<Typography
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: "16px",
						textOverflow: "ellipsis",
						overflow: "hidden",
					}}
				>
					{product.titre}
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						backgroundColor: "white",
						marginTop: 1,
					}}
				>
					<PriceFormater
						price={product.prix}
						withTitle={false}
						withTimeFrame={false}
						withSign={false}
						withTaxes={false}
					/>
					<a href={`/panier?id=${product.id}`}>
						<IconButton
							sx={{
								marginLeft: 1,
								backgroundColor: "transparent",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "45px",
								height: "45px",
								border: (theme) => `1px solid ${theme.palette.secondary.main}`,
								"& > svg": {
									color: (theme) => theme.palette.secondary.main,
								},
								"&:hover": {
									backgroundColor: (theme) => theme.palette.secondary.main,
									border: "none",
									"& > svg": {
										color: "white",
									},
								},
							}}
						>
							<ShoppingBagOutlined
								sx={{
									marginTop: "-2px",
									color: (theme) => theme.palette.secondary.main,
								}}
							/>
						</IconButton>
					</a>
				</Box>
			</Box>
		</InexCard>
	);
};

const ListMagazines = ({ products, ...props }) => {
	const theme = useTheme();
	const isMobile = useIsMobile();

	const swiperRef = React.useRef(null);
	const boxRef = React.useRef(null);
	const [slidesPerView, setSlidesPerView] = React.useState(null);
	const [progress, setProgress] = React.useState(0);

	React.useLayoutEffect(() => {
		if (boxRef.current) {
			const onWindowSizeChanged = () => {
				const rect = boxRef.current.getBoundingClientRect();
				setSlidesPerView(parseInt(rect.width / 250, 10));
			};
			onWindowSizeChanged();
			window.addEventListener("resize", onWindowSizeChanged);
			return () => {
				window.removeEventListener("resize", onWindowSizeChanged);
			};
		}
	}, [boxRef.current]);

	const handleGoNext = React.useCallback(() => {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
		}
	}, [swiperRef.current]);

	const handleGoPrevious = React.useCallback(() => {
		if (swiperRef.current) {
			swiperRef.current.slidePrev();
		}
	}, [swiperRef.current]);

	const canGoNext = React.useMemo(() => {
		return progress < 1;
	}, [progress]);

	const canGoPrev = React.useMemo(() => {
		return progress > 0;
	}, [progress]);

	return (
		<Box
			ref={(element) => {
				if (element) {
					boxRef.current = element;
				}
			}}
			sx={{
				width: "100%",
				height: "100%",
				position: "relative",
			}}
		>
			{!products || slidesPerView === null ? (
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						overflow: "visible",
					}}
				>
					{[1, 2, 3, 4, 5, 6].map((item, index) => {
						return (
							<Skeleton
								key={"skeleton-" + index}
								variant={"rectangular"}
								width={"100%"}
								height={"300px"}
								sx={{
									minWidth: "200px",
									marginRight: theme.spacing(3),
									aspectRatio: 174 / 224,
								}}
							/>
						);
					})}
				</Box>
			) : (
				<Fade in={true} timeout={300}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							width: "100%",
							maxWidth: "100%",
							position: "relative",
						}}
					>
						{!isMobile && (
							<IconButton
								onClick={handleGoPrevious}
								disabled={!canGoPrev}
								sx={{
									position: "absolute",
									backgroundColor: (theme) => theme.palette.background.paper,
									top: "50%",
									left: 0,
									transform: "translateY(-50%)",
									marginLeft: 1,
									marginRight: 1,
									color: (theme) => theme.palette.secondary.main,
									zIndex: 1000,
									"&:hover": {
										color: (theme) => theme.palette.background.paper,
										backgroundColor: (theme) => theme.palette.secondary.main,
									},
								}}
							>
								<KeyboardArrowLeftRounded
									sx={{
										fontSize: "35px",
									}}
								/>
							</IconButton>
						)}
						<Box
							sx={{
								width: "100%",
								maxWidth: "100%",
								overflowX: isMobile ? "visible" : "auto",
								scrollbarWidth: "none",
								padding: theme.spacing(3),
								paddingRight: isMobile ? undefined : `calc(51px + ${theme.spacing(2)})`,
								paddingLeft: isMobile ? undefined : `calc(51px + ${theme.spacing(2)})`,
							}}
						>
							<Swiper
								spaceBetween={theme.spacing(3)}
								slidesPerView={slidesPerView}
								pagination={{ clickable: true }}
								scrollbar={{ draggable: true }}
								style={{
									overflow: "visible",
								}}
								onSlideChange={(swiper) => {
									setProgress(swiper.progress);
								}}
								onSwiper={(swiper) => {
									swiperRef.current = swiper;
								}}
							>
								{products?.map((product) => {
									return (
										<SwiperSlide
											key={product.id}
											style={{
												height: "auto",
											}}
										>
											<MagazineCard product={product} />
										</SwiperSlide>
									);
								})}
							</Swiper>
						</Box>
						{!isMobile && (
							<IconButton
								onClick={handleGoNext}
								disabled={!canGoNext}
								sx={{
									position: "absolute",
									backgroundColor: (theme) => theme.palette.background.paper,
									top: "50%",
									right: 0,
									transform: "translateY(-50%)",
									marginLeft: 1,
									marginRight: 1,
									color: (theme) => theme.palette.secondary.main,
									zIndex: 1,
									"&:hover": {
										color: (theme) => theme.palette.background.paper,
										backgroundColor: (theme) => theme.palette.secondary.main,
									},
								}}
							>
								<KeyboardArrowRightRounded
									sx={{
										fontSize: "35px",
									}}
								/>
							</IconButton>
						)}
					</Box>
				</Fade>
			)}
		</Box>
	);
};

export default ListMagazines;
