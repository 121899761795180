import { api } from "./rtkApi";

export const odysseeApi = api.injectEndpoints({
	endpoints: (build) => ({
		getLastOdyssee: build.query({
			query: ({ authToken }) => {
				return {
					method: "GET",
					url: `/odyssee/last`,
					headers: {
						authorization: "Bearer " + authToken,
					},
				};
			},
		}),
		getOdysseeBySlug: build.query({
			query: ({ authToken, slug }) => {
				return {
					method: "GET",
					url: `/odyssee/get/${slug}`,
					headers: {
						authorization: "Bearer " + authToken,
					},
				};
			},
		}),
		getOdysseePrice: build.query({
			query: ({ authToken, id }) => {
				return {
					method: "GET",
					url: `/product/get-price/${id}`,
					headers: {
						authorization: "Bearer " + authToken,
					},
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetLastOdysseeQuery,
	useLazyGetLastOdysseeQuery,
	useGetOdysseeBySlugQuery,
	useLazyGetOdysseeBySlugQuery,
	useGetOdysseePriceQuery,
	useLazyGetOdysseePriceQuery,
} = odysseeApi;
