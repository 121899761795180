import React from "react";
import { Box, Fade, Typography } from "@mui/material";

const OdysseeFeaturePoint = ({ icon, text, delay = 0, background, textColor, ...props }) => {
	const [show, setShow] = React.useState(false);

	React.useEffect(() => {
		setTimeout(() => {
			setShow(true);
		}, delay);
	}, []);

	return (
		<Fade in={show} timeout={500}>
			<Box
				{...props}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
					height: "100%",
					...props.sx,
				}}
			>
				<Box
					sx={{
						width: 74,
						height: 74,
						borderRadius: "50%",
						backgroundColor: background,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{React.cloneElement(icon, {
						...icon.props,
						sx: {
							...icon.props.sx,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: 40,
							height: 40,
						},
					})}
				</Box>
				<Typography
					sx={{
						marginTop: 2,
						fontFamily: "Branding Bold",
						fontSize: "16px",
						lineHeight: "1.2em",
						color: textColor,
						maxWidth: "150px",
						textAlign: "center",
					}}
				>
					{text}
				</Typography>
			</Box>
		</Fade>
	);
};

export default OdysseeFeaturePoint;
